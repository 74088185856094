$accent-color: #666;
.post {
  h1, h2, h3, h4, h5, h6 {
    &::first-letter {
      color: $accent-color;
    }
  }
  h1 {
    line-height: 1.2;
  }
  h2 {
    margin-top: 60px;
    border-bottom: 1px solid $accent-color;
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 40px;
    border-left: 3px solid $accent-color;
    padding-left: 10px;
  }
}

.post-list {
  & > li {
    border-left: 5px solid $accent-color;
    padding-left: 20px;
    h3 {
      line-height: 1.2;
      font-size: 20px;
    }
  }
}
.post-content {
  p {
    font-size: 18px;
    line-height: 2;
    word-break: break-all;  
  }
  img, iframe {
    max-width: 100%;
    max-height: 400px;
    display: block;
    margin: 20px auto;
  }
  .panel-images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    img {
      margin: 10px auto;
      max-height: none;
      max-width: calc(50% - 10px);
    }
  }
}
